<template>
  <div>
    <v-dialog v-model="popup_open" max-width="400px" @click:outside="close">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          {{ message.title }}
        </v-card-title>

        <v-card-text class="justify-center">
          <div class="px-6 py-6 text-center">
            <v-progress-circular
              indeterminate
              color="red"
            ></v-progress-circular>
            <span class="text-h4 px-6"> {{ message.text }}</span>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <!-- <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close"> OK </v-btn>
        </v-card-actions> -->
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data: (vm) => ({
    popup_open: false,
  }),

  props: {
    message: {
      type: Object,
      default: () => ({
        title: "",
        text: "",
      }),
    },
    dialog_message: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    close() {
      this.popup_open = false;
      // emit close event
      this.$emit("close");
    },
  },

  watch: {
    dialog_message(val) {
      this.popup_open = val;
    },
  },
};
</script>
